import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { User } from "../../types/app";

export interface AuthState {
  token: string | null;
  user: User | null;
}

const initialState: AuthState = {
  token: null,
  user: {
    id: null,
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    country_code: "",
    is_identity_verified: false,
    wallet: {
      account_details: {
        bank_name: "",
        vnuban: 0,
      },
      balance: 0,
      currency_code: "",
      card_details: {},
    },
    is_new: false,
    business: {
      address: "",
      country_code: "",
      created_at: "",
      id: 0,
      is_deleted: false,
      location: "",
      mobile: "",
      name: "",
      payment_label: "",
      profile_image_upload_id: 0,
      profile_image_url: "",
      signature_image_upload_id: 0,
      signature_image_url: "",
      slug: "",
      updated_at: "",
      user_id: 0,
    },
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUserState: (state, { payload }: PayloadAction<User>) => {
      return { ...state, user: payload };
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
});

export const { updateUserState, setToken } = authSlice.actions;

export const authSelector = (state: RootState) => state.auth;

export default authSlice.reducer;
