import React from "react";
import { Pressable, Text, View } from "react-native";
import { Button, Header, tw, withModal } from "@shara-inc/design-system-mobile";
import { useNavigation } from "@react-navigation/native";
import Logout from "@shara-inc/design-system-mobile/assets/icons/components/Outline/Interface/Logout";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { AppStackParamList } from "../App";
import { authSelector, setToken } from "../store/auth/slice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const AppHeader = withModal(({ openModal }) => {
  const dispatch = useAppDispatch();
  const businessData = useAppSelector(authSelector);

  const business = {
    name: businessData?.user?.business?.name,
  };

  const navigation =
    useNavigation<NativeStackNavigationProp<AppStackParamList>>();

  const logOut = async () => {
    await AsyncStorage.clear();
    dispatch(setToken(null));
    navigation.reset({
      index: 0,
      routes: [{ name: "Splash" }],
    });
  };
  return (
    <Header
      title={business.name}
      mode={"business"}
      rightChild={
        <Pressable
          onPress={() => {
            const logOutModal = openModal("bottom-half", {
              title: "Confirm",
              renderContent: () => {
                return (
                  <>
                    <Text style={tw`text-base text-center mt-4 mx-2.5`}>
                      Are you sure you want to log out?
                    </Text>
                    <View style={tw`flex-row w-full mt-8`}>
                      <Button
                        onPress={() => {
                          logOutModal();
                        }}
                        title={"Go back"}
                        containerStyle={tw`bg-gray-200 flex-1 mr-1.5`}
                      />
                      <Button
                        onPress={async () => {
                          logOutModal();
                          await logOut();
                        }}
                        title={"Log out"}
                        mode={"success"}
                        containerStyle={tw`flex-1 ml-1.5`}
                      />
                    </View>
                  </>
                );
              },
            });
          }}
        >
          <Logout width={32} color={"#B9B9B9"} />
        </Pressable>
      }
    />
  );
});
