import React from "react";
import { useLinkProps } from "@react-navigation/native";
import { Button, ButtonProps } from "@shara-inc/design-system-mobile";
import { NavigationAction } from "@react-navigation/core";

type LinkButtonProps = Omit<ButtonProps, "onPress"> & {
  to: string;
  action?: NavigationAction;
};

const LinkButton = ({ to, action, ...rest }: LinkButtonProps) => {
  const { onPress, ...props } = useLinkProps({ to, action });
  return <Button onPress={onPress} {...props} {...rest} />;
};

export default LinkButton;
