import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { ApiResponse, DisbursementMethod } from "../../types/app";
import { axiosInstance, baseURL } from "../config";

export type FieldsData = Array<{
  key: string;
  label: string;
  value:
    | string
    | {
        label: string;
        value: string;
      };
  required?: string;
  type?: string;
  options?: Array<{
    label: string;
    value: string;
  }>;
}>;

export type DisbursementOption = {
  name: string;
  slug: string;
  is_primary: boolean;
  fields: FieldsData;
};

export const fetchProviders = async () => {
  return axiosInstance.get<null, ApiResponse<{}>>(`/disbursement-provider`);
};

export const fetchDisbursementMethods = async () => {
  const token = await AsyncStorage.getItem("user-token");
  return axios.get<ApiResponse<{ disbursementMethods: DisbursementMethod[] }>>(
    `${baseURL}/disbursement-method`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const saveDisbursementMethod = async (data: {
  bankName: string;
  bankCode: string;
  accountHolder: string;
  bankAccount: string;
}) => {
  const token = await AsyncStorage.getItem("user-token");
  return await axios.post(
    `${baseURL}/disbursement-method`,
    {
      name: "Bank",
      slug: "woven-bank",
      is_primary: true,
      fields: [
        {
          key: "bank_code",
          label: "Bank",
          value: JSON.stringify({
            label: data?.bankName,
            value: data?.bankCode,
          }),
        },
        {
          key: "account_name",
          label: "Account name",
          value: data?.accountHolder,
        },
        {
          key: "nuban",
          label: "Account number",
          value: data?.bankAccount,
        },
      ],
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const accountNameLookup = async (data: {
  nuban: string;
  bank_code: string;
  provider: string;
}): Promise<any> => {
  return axiosInstance.get<null, ApiResponse<{}>>(
    `/woven/account-number-resolve?nuban=${data?.nuban}&bank_code=${data?.bank_code}`
  );
};

export const addJengaMethod = async (data: {
  businessName: string;
  accountNumber: string;
  accountHolder: string;
  bankName: string;
  bankCode: string;
  token: string;
}) => {
  const fieldsData = [
    {
      key: "bank_code",
      label: "Bank",
      value: JSON.stringify({
        label: `${data?.bankName}`,
        value: `${data?.bankCode}`,
      }),
    },
    {
      key: "account_name",
      label: "Account name",
      value: data?.accountHolder,
    },
    {
      key: "account_number",
      label: "Account number",
      value: data?.accountNumber,
    },
  ];

  return axios.post<null, ApiResponse<{}>>(
    `${baseURL}/disbursement-method`,
    {
      slug: "jenga",
      is_primary: true,
      fields: fieldsData,
    },
    {
      headers: {
        Authorization: `Bearer ${data?.token}`,
      },
    }
  );
};

export const fetchJengaBanks = async () => {
  return axiosInstance.get<null, ApiResponse<{}>>(
    `/disbursement-provider?country_code=254`
  );
};

export const fetchNigerianBanks = async () => {
  return axiosInstance.get<null, ApiResponse<{}>>(
    `/disbursement-method/bank-codes?provider=paga`
  );
};
