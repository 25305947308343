import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import {
  AppInput,
  Button,
  Header,
  Notification,
  tw,
  withModal,
} from "@shara-inc/design-system-mobile";
import { AuthHeader } from "../components/AuthHeader";
import { useNavigation } from "@react-navigation/native";
import { ScreenProps } from "../App";
import Lock from "@shara-inc/design-system-mobile/assets/icons/components/Solid/Status/Lock";
import Check from "@shara-inc/design-system-mobile/assets/icons/components/Solid/Interface/Check";
import {
  accountNameLookup,
  fetchNigerianBanks,
  saveDisbursementMethod,
} from "../api/disbursement";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useAppDispatch } from "../store/hooks";
import { setToken, updateUserState } from "../store/auth/slice";
import { useToast } from "react-native-toast-notifications";
import { createKyc, fetchUserKyc, queueSupplierComms, updateKyc } from "../api";
import CaretDown from "@shara-inc/design-system-mobile/assets/icons/components/Outline/Interface/CaretDown";
import Search from "@shara-inc/design-system-mobile/assets/icons/components/Outline/Interface/Search";
import { BankData } from "../types/app";
import { SelectList } from "react-native-dropdown-select-list";

const AddAccount: React.FC<ScreenProps<"AddAccount">> = withModal(
  ({ openModal, route }) => {
    const dispatch = useAppDispatch();

    const [bankName, setBankName] = useState<string>("");
    const [bankAccount, setBankAccount] = useState<string>("");
    const [bankCode, setBankCode] = useState<string>("");
    const [accountHolder, setAccountHolder] = useState<string>("");
    const [searchLoading, setSearchLoading] = useState<boolean>(false);
    const navigation = useNavigation();
    const [providerCopy, setproviderCopy] = useState([]);
    const [providers] = useState([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const toast = useToast();
    const [kycId, setKycId] = useState<string>("");
    const { rcNumber, businessName } = route.params!;
    const [selected, setSelected] = React.useState("");

    const createKYC = async () => {
      try {
        await createKyc();
      } catch (error) {}
    };

    const fetchKYC = async () => {
      try {
        const response = await fetchUserKyc();
        setKycId(response?.data[0].id);
      } catch (error) {}
    };

    useEffect(() => {
      (async function () {
        const res = await fetchNigerianBanks();
        //@ts-ignore
        const result: BankData[] = res?.data;
        const mapBank = result?.map((item) => ({
          value: item?.bankName,
          key: item?.sharaCode,
        }));
        const sortedBank = mapBank?.sort((a, b) =>
          a.value.toLowerCase().localeCompare(b.value.toLowerCase())
        );
        setproviderCopy(sortedBank);
        createKYC();
        fetchKYC();
      })();
    }, []);

    useEffect(() => {
      if (providers) {
        const filter = providers?.find((item) => item?.id === bankCode);
        setBankName(filter?.value);
      } else {
        return;
      }
    }, [bankCode, providers]);

    const onSubmit = async () => {
      setLoading(true);
      const closeLoadingModal = openModal("loading", {
        text: "Just a moment while we update your information",
      });
      try {
        await saveDisbursementMethod({
          bankName,
          bankCode,
          accountHolder,
          bankAccount,
        });

        await updateKyc({
          kycId,
          businessName,
          rcNumber,
          business_account_details: JSON.stringify([
            {
              bank_account_number: bankAccount,
              account_name: accountHolder,
              bank_name: bankName,
              bank_code: bankCode,
            },
          ]),
        });
        await queueSupplierComms({ status: true });

        const tmpData = await AsyncStorage.getItem("temp-user-obj");
        const parsedData = JSON.parse(tmpData);
        const newUserState = {
          ...parsedData?.user,
          business: {
            //@ts-ignore
            name: route?.params?.businessName,
          },
        };
        setLoading(false);
        dispatch(updateUserState(newUserState));
        closeLoadingModal();
        const closeMessageModal = openModal("message", {
          title: "Registration Successful",
          icon: (
            <Check
              width={40}
              height={40}
              color={tw`text-[#212121]`.color as string}
            />
          ),
          containerStyle: tw`bg-[#212121]`,
          buttonProps: {
            title: "Home",
            onPress: () => {
              closeMessageModal();
              dispatch(setToken(parsedData?.token));
            },
          },
        });
        await AsyncStorage.removeItem("temp-user-obj");
      } catch (error) {
        closeLoadingModal();
        await queueSupplierComms({ status: false });
        toast.show((error as Error).message, {
          type: "danger",
        });
        setLoading(false);
      }
    };

    const accountLookup = async (code?: string, account?: string) => {
      setAccountHolder("");
      try {
        if (code && account.length === 10) {
          setSearchLoading(true);
          const response = await accountNameLookup({
            nuban: account,
            bank_code: code,
            provider: "paga",
          });
          setAccountHolder(response.data.account_name);
          setSearchLoading(false);
        }
      } catch (error) {
        setAccountHolder("Account not found");
        setSearchLoading(false);
      }
    };

    useEffect(() => {
      setBankCode(bankCode);
    }, [bankCode]);

    return (
      <SafeAreaView style={tw`flex-1 bg-white`}>
        <Header
          onBackPress={() => {
            navigation.goBack();
          }}
          title={""}
        />
        <ScrollView style={tw`flex-1`}>
          <TouchableWithoutFeedback>
            <View style={tw`items-center mt-2 flex-1 px-4`}>
              <AuthHeader
                title={"Enter your bank account details"}
                description={
                  "Account details are only required for the disbursement of the funds into your account."
                }
                containerStyle={tw`mb-2`}
              />
              <View style={tw`flex-1 w-full`}>
                <View style={tw`flex-1 w-full mb-4 z-1000`}>
                  <AppInput
                    label={"Bank Account Number"}
                    onChangeText={(text) => {
                      setBankAccount(text);
                      if (text.length === 10 && selected !== "0") {
                        accountLookup(bankCode, text);
                      }
                    }}
                    style={tw`mb-5`}
                    maxLength={10}
                    keyboardType={"number-pad"}
                  />
                  <Text>Bank</Text>
                  <SelectList
                    onSelect={() => {
                      setBankCode(selected);
                      accountLookup(selected, bankAccount);
                    }}
                    setSelected={setSelected}
                    data={providerCopy}
                    arrowicon={
                      <CaretDown
                        width={20}
                        height={20}
                        color={tw.style("text-black").color as string}
                      />
                    }
                    searchicon={
                      <Search
                        width={20}
                        height={20}
                        color={tw.style("text-black").color as string}
                      />
                    }
                    search={true}
                    boxStyles={tw`rounded-4 mt-2`}
                    defaultOption={{ key: "0", value: "Select Bank" }}
                  />
                  <View style={tw`flex-row align-items mt-4 mb-4`}>
                    {searchLoading && <ActivityIndicator size={"small"} />}
                    {accountHolder && (
                      <Text style={tw`ml-4`}>{accountHolder}</Text>
                    )}
                  </View>
                </View>
                <Notification
                  message={
                    "Your information will be encrypted, stored securely and is only used to verify your identity."
                  }
                  style={tw`mb-6`}
                  showIcon
                  icon={<Lock width={12} color={"white"} />}
                />
                <View style={tw``}>
                  <Button
                    onPress={async () => {
                      await onSubmit();
                    }}
                    mode={"success"}
                    type={"long"}
                    title={"Next"}
                    loading={isLoading}
                    disabled={
                      !accountHolder ||
                      !bankAccount ||
                      !bankCode ||
                      bankAccount.length < 10
                    }
                  />
                </View>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </ScrollView>
      </SafeAreaView>
    );
  }
);

export default AddAccount;
