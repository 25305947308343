import React, { useEffect, useState } from "react";
import {
  SafeAreaView,
  View,
  Text,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import { Button, Header, OTPInput, tw } from "@shara-inc/design-system-mobile";
import { AuthHeader } from "../components/AuthHeader";
import { fetchUserKyc, logIn, sendOtp } from "../api";
import { ScreenProps } from "../App";
import { useAppDispatch } from "../store/hooks";
import { setToken, updateUserState } from "../store/auth/slice";
import { useLinkProps } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useToast } from "react-native-toast-notifications";
import { checkAboutSectionCompletion } from "../lib/util";
import { fetchDisbursementMethods } from "../api/disbursement";

const START_MINUTES = 2;
const START_SECONDS = 0;

const OtpVerificationScreen: React.FC<ScreenProps<"OtpVerification">> = ({
  navigation,
  route,
}) => {
  const { mobile, country_code } = route.params;
  const [loading, setLoading] = useState<boolean>(false);
  const [defaultOtp, setOTP] = useState<string>("");
  const [minutes, setMinutes] = useState(START_MINUTES);
  const [seconds, setSeconds] = useState(START_SECONDS);
  const [isTimerExpired, setTimerStatus] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [businessName, setBusinessName] = useState<string>("");

  const redirectPath = country_code === "234" ? "AddBusiness" : "VerifyId";

  const { onPress: handleNavigation } = useLinkProps({
    to: {
      screen: redirectPath,
    },
  });

  const { onPress: handleAddAccount } = useLinkProps({
    to: {
      screen: "AddAccount",
      params: {
        businessName,
      },
    },
  });

  const { onPress: handleAccountDetailsNavigation } = useLinkProps({
    to: {
      screen: "AddKEAccount",
    },
  });

  const spaceMobileNumber = () => {
    if (country_code === "234") {
      return `+${country_code}${mobile}`.replace(
        /(\d{3})(\d{3})(\d{3})(\d{4})/,
        "$1 $2 $3 $4"
      );
    }
    return `+${country_code}${mobile}`.replace(
      /(\d{3})(\d{3})(\d{3})(\d{3})/,
      "$1 $2 $3 $4"
    );
  };

  const { onPress: handleAccountCreate } = useLinkProps({
    to: {
      screen: "UpdateUserName",
      params: {
        country_code,
      },
    },
  });

  useEffect(() => {
    let timerInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(timerInterval);
          setTimerStatus(true);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(timerInterval);
    };
  });

  const handleVerifyOtp = async (code?: string) => {
    try {
      let userData = {
        password: code,
        mobile: `${country_code}${mobile}`,
      };
      setLoading(true);
      const otpData = await logIn(userData);
      await AsyncStorage.setItem("user-token", otpData?.token);
      await AsyncStorage.setItem("user-id", `${otpData?.user?.id}`);
      if (otpData?.user?.business !== null) {
        await AsyncStorage.setItem("has-business", "true");
      }
      const kycData = await fetchUserKyc();
      await AsyncStorage.setItem(
        "temp-user-obj",
        JSON.stringify({
          user: otpData?.user,
          //@ts-ignore
          token: otpData?.token,
        })
      );
      setLoading(false);
      if (kycData?.data.length > 0) {
        const aboutResult = checkAboutSectionCompletion({
          ...kycData?.data[0]?.about,
        });
        const {
          data: {
            data: { disbursementMethods },
          },
        } = await fetchDisbursementMethods();
        const hasBusinessDetails = !!disbursementMethods.length;
        if (!aboutResult?.firstname && !aboutResult?.lastname) {
          handleAccountCreate();
          return;
        } else if (
          (!aboutResult?.national_id && country_code === "254") ||
          (!aboutResult?.business_name &&
            country_code === "234" &&
            otpData?.user?.business === null)
        ) {
          handleNavigation();
          return;
        } else if (!hasBusinessDetails && country_code === "254") {
          handleAccountDetailsNavigation();
          return;
        } else if (
          (aboutResult?.business_name || otpData?.user?.business) &&
          country_code === "234" &&
          !hasBusinessDetails
        ) {
          setBusinessName(otpData?.user?.business?.name);
          handleAddAccount();
        } else {
          await AsyncStorage.removeItem("temp-user-obj");
          await AsyncStorage.setItem(
            "userDetails",
            JSON.stringify({
              user: otpData?.user,
              //@ts-ignore
              token: otpData?.token,
            })
          );
          dispatch(setToken(otpData?.token));
          dispatch(updateUserState(otpData.user));
        }
      } else {
        handleAccountCreate();
        return;
      }
    } catch (error) {
      setLoading(false);
      toast.show((error as Error).message, {
        type: "danger",
      });
    }
  };

  const resendOTPCode = async () => {
    setMinutes(START_MINUTES);
    setTimerStatus(false);
    setOTP("");

    try {
      await sendOtp({
        country_code: country_code,
        mobile,
        auth_type: "supplier",
      });
      toast.show("OTP Sent", {
        type: "success",
      });
    } catch (err: any) {
      if (err?.response?.data) {
        toast.show((err as Error).message, {
          type: "danger",
        });
      }
    }
  };

  return (
    <SafeAreaView style={tw`flex-1 bg-white`}>
      <Header onBackPress={navigation.goBack} title={""} />
      <View style={tw`flex-1 px-4 mb-6`}>
        <View style={tw`items-center mt-2 flex-1`}>
          <AuthHeader
            title={"Enter Code"}
            description={
              <>
                <Text>{"Enter the 6 digit code sent to\n"}</Text>
              </>
            }
            containerStyle={tw`mb-2`}
          />
          <Text style={tw`text-lg font-700 text-gray-600 mt--5 mb-10`}>
            {spaceMobileNumber()}
          </Text>
          <OTPInput
            pinCount={6}
            onCodeFilled={handleVerifyOtp}
            handleOtpChange={(code) => setOTP(code)}
          />
          <Text style={tw`mt-20 text-gray-400 leading-6 text-center`}>
            Didn’t receive the code?{"\n"}
            <Text
              onPress={() => (isTimerExpired ? resendOTPCode() : null)}
              style={styles.resendText}
            >
              <Text
                style={
                  isTimerExpired
                    ? styles.activeResendButton
                    : styles.inActiveResendButton
                }
              >
                Resend Code
              </Text>{" "}
              in {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </Text>
          </Text>
        </View>
        {!loading && defaultOtp.length < 6 && (
          <Button
            onPress={() => null}
            mode={"success"}
            type={"long"}
            title={"Next"}
            loading={loading}
            disabled={defaultOtp.length < 6 || loading}
          />
        )}
        {loading && defaultOtp.length === 6 && (
          <View style={tw`mb-20`}>
            <ActivityIndicator size={"large"} />
          </View>
        )}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  resendText: {
    fontSize: 9,
    color: "#B9B9B9",
  },
  activeResendButton: {
    textDecorationLine: "underline",
    color: "#1B8B70",
    fontWeight: "bold",
  },
  inActiveResendButton: {
    textDecorationLine: "underline",
    color: "rgba(0,0,0,0.7)",
  },
});

export default OtpVerificationScreen;
