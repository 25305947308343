import {
  Button,
  tw,
  ReceiptContainer,
  Card,
  ReceiptDivider,
} from "@shara-inc/design-system-mobile";
import React, { memo, useEffect, useRef, useState } from "react";
import { ActivityIndicator, Text, View } from "react-native";
//@ts-ignore
import ViewShot from "react-native-view-shot-with-web-support";
import { authSelector } from "../store/auth/slice";
import { useAppSelector } from "../store/hooks";
// import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import { Drawdown, fetchSingleDrawdown } from "../api/drawdowns";
import { format } from "date-fns";

type ReceiptImageProps = {
  transaction: {
    customer: { name: string };
    drawdown_id: number;
  };
  onClose: () => void;
};

export const ReceiptImage = memo(
  ({ transaction, onClose }: ReceiptImageProps) => {
    const [drawdownData, setDrawdownData] = useState<Drawdown>();
    const [loading, setLoading] = useState<boolean>(true);
    // const [showBtn, setShowBtn] = useState<boolean>(true);
    const businessData = useAppSelector(authSelector);

    const business = {
      name: businessData?.user?.business?.name,
    };
    const { drawdown_id } = transaction;

    useEffect(() => {
      (async () => {
        try {
          const res = await fetchSingleDrawdown(drawdown_id);
          //@ts-ignore
          setDrawdownData(res?.data?.drawdown);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      })();
    }, [drawdown_id]);

    const viewShot = useRef<any>(null);

    /*
    const onOthersShare = useCallback(async () => {
      const url = await viewShot.current.capture();
      await Share.share({
        url,
        title: "Transaction receipt",
        message: `${customer.name}'s transaction receipt`,
      });
    }, [customer.name]);
    */

    const launchPrint = () => {
      window.print();
    };

    const amountWithCurrency = (currency_code, amount) => {
      return currency_code === "NGN" ? `₦${amount}` : `Ksh${amount}`;
    };

    if (loading) {
      return (
        <View
          style={tw.style("flex-1 bg-[#FFFFFF] items-center justify-center")}
        >
          <ActivityIndicator size={"large"} />
          <Text style={tw.style("p-10 text-base font-medium")}>
            Loading receipt...
          </Text>
        </View>
      );
    }

    return (
      <ViewShot
        ref={viewShot}
        options={{
          format: "png",
          quality: 0.8,
        }}
      >
        <ReceiptContainer business={business} onClose={onClose}>
          <Card
            containerStyle={tw.style("px-8")}
            title={
              drawdownData?.status === "cancelled"
                ? "Cancelled Transaction"
                : "Your Transaction Details"
            }
            titleStyle={tw.style("text-xl font-medium mb-4 mx-auto")}
          />
          <Card
            containerStyle={tw.style("px-8 py-1")}
            title={"Date:"}
            titleStyle={tw.style("text-sm")}
            description={`${format(
              new Date(drawdownData?.created_at),
              "dd MMMM, yyy"
            )}`}
            descriptionStyle={tw.style("font-medium")}
            rightChild={
              <Card
                title={"Ref #"}
                titleStyle={tw.style("text-sm text-right ml-auto")}
                description={`${drawdownData?.id}`}
                descriptionStyle={tw.style("font-medium")}
              />
            }
          />
          <Card
            containerStyle={tw.style("px-8 py-1")}
            title={business?.name}
            titleStyle={tw.style("text-sm")}
            description={`${businessData?.user?.mobile}`}
            descriptionStyle={tw.style("font-medium")}
            rightChild={
              <Card
                title={"Buyer:"}
                titleStyle={tw.style("text-sm ml-auto text-right")}
                description={`${drawdownData?.user?.firstname || ""}`}
                descriptionStyle={tw.style("font-medium")}
              />
            }
          />
          <ReceiptDivider showSides={false} containerStyle={tw.style("mx-4")} />
          <Card
            containerStyle={tw.style("px-8 pt-2")}
            title={"Total Amount:"}
            rightChild={
              <Card
                containerStyle={tw.style("")}
                title={`${amountWithCurrency(
                  drawdownData?.currency_code,
                  drawdownData?.amount_drawn
                )}`}
              />
            }
          />
          <ReceiptDivider containerStyle={tw.style("")} />
          {/* {showBtn && ( */}
          <Button
            mode={"success"}
            inverted
            onPress={launchPrint}
            title={"Download Receipt"}
            textStyle={tw.style("text-brand-green-400")}
            containerStyle={tw.style(
              "mt-6 rounded-3 mx-8 border-brand-green-400"
            )}
          />
        </ReceiptContainer>
      </ViewShot>
    );
  }
);
