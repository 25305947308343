import React, { useEffect, useState } from "react";
import { SafeAreaView, View } from "react-native";
import {
  AppInput,
  Button,
  Header,
  tw,
  withModal,
} from "@shara-inc/design-system-mobile";
import { AuthHeader } from "../components/AuthHeader";
import { useLinkProps, useNavigation } from "@react-navigation/native";
import { ScreenProps } from "../App";
import { createKyc, fetchUserKyc, updateKyc, userNameUpdate } from "../api";
import { useToast } from "react-native-toast-notifications";

const UpdateUserName: React.FC<ScreenProps<"CreateAccount">> = withModal(
  ({ route }) => {
    const [firstname, setFirstName] = useState<string>("");
    const [lastname, setLastName] = useState<string>("");
    const navigation = useNavigation();
    const [isLoading, setLoading] = useState<boolean>(false);
    const toast = useToast();
    const [kycId, setKycId] = useState<string>("");

    const redirectPath =
      route.params.country_code === "234" ? "AddBusiness" : "VerifyId";

    const { onPress: handleNavigation } = useLinkProps({
      to: {
        screen: redirectPath,
      },
    });

    const onSubmit = async () => {
      setLoading(true);
      try {
        await userNameUpdate({ firstname, lastname });
        await updateKyc({
          kycId,
          firstname,
          lastname,
        });
        setLoading(false);
        handleNavigation();
      } catch (error) {
        setLoading(false);
        toast.show(error?.response?.data.message, {
          type: "danger",
        });
      }
    };

    const createKYC = async () => {
      try {
        const createData = await createKyc();
        if (createData.data) {
          setKycId(createData?.data?.id);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchKYC = async () => {
      try {
        const response = await fetchUserKyc();
        setKycId(response?.data[0].id);
      } catch (error) {}
    };

    useEffect(() => {
      createKYC();
      fetchKYC();
    }, []);
    return (
      <SafeAreaView style={tw`flex-1 bg-white`}>
        <Header
          onBackPress={() => {
            navigation.goBack();
          }}
          title={""}
        />
        <View style={tw`flex-1 mb-6`}>
          <View style={tw`items-center mt-2 flex-1 px-4`}>
            <AuthHeader
              title={"Create your account"}
              description={"We need these details to verify your identity."}
              containerStyle={tw`mb-2`}
            />
            <AppInput
              label={"First Name"}
              style={tw`mb-3`}
              defaultValue={firstname}
              onChangeText={(text) => setFirstName(text)}
              maxLength={69}
            />
            <AppInput
              label={"Last Name"}
              onChangeText={(text) => {
                let trimmedText = text.trim();
                setLastName(trimmedText);
              }}
              maxLength={69}
              value={lastname}
            />
          </View>
        </View>
        <View style={tw`m-4`}>
          <Button
            onPress={async () => {
              await onSubmit();
            }}
            mode={"success"}
            type={"long"}
            title={"Next"}
            disabled={!firstname || !lastname}
            loading={isLoading}
          />
        </View>
      </SafeAreaView>
    );
  }
);

export default UpdateUserName;
