import React, { useCallback, useEffect, useState } from "react";
import { SafeAreaView, ScrollView, View } from "react-native";
import {
  AppInput,
  Button,
  Header,
  Notification,
  SelectInput,
  tw,
  withModal,
} from "@shara-inc/design-system-mobile";
import { AuthHeader } from "../components/AuthHeader";
import { useNavigation } from "@react-navigation/native";
import { ScreenProps } from "../App";
import Lock from "@shara-inc/design-system-mobile/assets/icons/components/Solid/Status/Lock";
import Check from "@shara-inc/design-system-mobile/assets/icons/components/Solid/Interface/Check";
import { addJengaMethod, fetchJengaBanks } from "../api/disbursement";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setToken, updateUserState } from "../store/auth/slice";
import { useAppDispatch } from "../store/hooks";
import {
  createBusiness,
  createKyc,
  fetchUserKyc,
  queueSupplierComms,
  updateKyc,
} from "../api";
import { useToast } from "react-native-toast-notifications";

const AddKEAccount: React.FC<ScreenProps<"AddKEAccount">> = withModal(
  ({ openModal }) => {
    const navigation = useNavigation();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [businessName, setBusinessName] = useState<string>("");
    const [accountNumber, setAccountNumber] = useState<string>("");
    const dispatch = useAppDispatch();
    const [kycId, setKycId] = useState<string>("");
    const toast = useToast();
    const [providers, setBankProviders] = useState([
      { label: "Select Bank", value: null },
    ]);
    const [bankName, setBankName] = useState<string>("");
    const [bankCode, setBankCode] = useState<string>("");
    const [accountHolder, setAccountHolder] = useState<string>("");

    const createKYC = async () => {
      try {
        await createKyc();
      } catch (error) {}
    };

    const fetchKYC = async () => {
      try {
        const response = await fetchUserKyc();
        setKycId(response?.data[0].id);
      } catch (error) {}
    };

    const fetchBanks = useCallback(async () => {
      try {
        const banks = await fetchJengaBanks();
        const bankData =
          //@ts-ignore
          banks?.data?.disbursementProviders[1].fields[0].options;
        let concatProvider = [...providers, ...bankData];
        setBankProviders(concatProvider);
      } catch (error) {}
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      fetchBanks();
      createKYC();
      fetchKYC();
    }, [fetchBanks]);

    const onSubmit = async () => {
      setLoading(true);
      const closeLoadingModal = openModal("loading", {
        text: "Just a moment while we update your information",
      });

      try {
        const tmpData = await AsyncStorage.getItem("temp-user-obj");
        const parsedData = JSON.parse(tmpData);
        const formData = new FormData();
        formData.append("name", businessName);
        formData.append("address", "");
        formData.append("location", JSON.stringify({ lat: 1.22, lng: 3.44 }));

        await addJengaMethod({
          businessName,
          accountNumber,
          accountHolder,
          bankName,
          bankCode,
          token: parsedData?.token,
        });

        const hasBusiness = await AsyncStorage.getItem("has-business");
        if (hasBusiness === null) {
          await createBusiness(formData);
        }

        await updateKyc({
          kycId,
          businessName,
          rcNumber: "",
          business_account_details: JSON.stringify([
            {
              bank_account_number: accountNumber,
              account_name: accountHolder,
              bank_name: bankName,
              bank_code: bankCode,
            },
          ]),
        });

        await queueSupplierComms({ status: true });

        setLoading(false);
        const newUserState = {
          ...parsedData?.user,
          business: {
            name: businessName,
          },
        };
        dispatch(updateUserState(newUserState));
        closeLoadingModal();

        const closeMessageModal = openModal("message", {
          title: "Registration Successful",
          icon: (
            <Check
              width={40}
              height={40}
              color={tw`text-[#212121]`.color as string}
            />
          ),
          containerStyle: tw`bg-[#212121]`,
          buttonProps: {
            title: "Home",
            onPress: () => {
              closeMessageModal();
              dispatch(setToken(parsedData?.token));
            },
          },
        });
      } catch (error) {
        closeLoadingModal();
        await queueSupplierComms({ status: false });
        setLoading(false);
        toast.show((error as Error).message, {
          type: "danger",
        });
      }
    };

    useEffect(() => {
      if (!bankCode) return;
      const findBankName = providers.find((item) => item?.value === bankCode);
      if (findBankName) {
        setBankName(findBankName.label);
      }
    }, [bankCode, providers]);

    return (
      <SafeAreaView style={tw`flex-1 bg-white`}>
        <Header
          onBackPress={() => {
            navigation.goBack();
          }}
          title={""}
        />
        <ScrollView style={tw`flex-1`}>
          <View style={tw`items-center mt-2 flex-1 px-4`}>
            <AuthHeader
              title={"Enter your bank account details"}
              description={
                "Account details are only required for the disbursement of the funds into your account."
              }
              containerStyle={tw`mb-2`}
            />
            <View style={tw`flex-1 w-full`}>
              <View style={tw`flex-1 w-full mb-4`}>
                <AppInput
                  label={"Business Name"}
                  onChangeText={(text) => {
                    setBusinessName(text);
                  }}
                  style={tw`mb-3`}
                  maxLength={255}
                />
                <AppInput
                  label={"Bank Account Number"}
                  onChangeText={(text) => {
                    setAccountNumber(text);
                  }}
                  style={tw`mb-3`}
                  maxLength={20}
                  keyboardType={"number-pad"}
                />
                <AppInput
                  label={"Account Name"}
                  onChangeText={(text) => {
                    setAccountHolder(text);
                  }}
                  style={tw`mb-3`}
                  maxLength={255}
                />
                <SelectInput
                  label={"Bank Name"}
                  onValueChange={(text) => {
                    if (text === "Select Bank") {
                      setBankCode("");
                      return;
                    }
                    //@ts-ignore
                    setBankCode(text);
                  }}
                  options={providers}
                />
              </View>
              <Notification
                message={
                  "Your information will be encrypted, stored securely and is only used to verify your identity."
                }
                style={tw`mb-6`}
                showIcon
                icon={<Lock width={12} color={"white"} />}
              />
              <View style={tw``}>
                <Button
                  onPress={async () => {
                    await onSubmit();
                  }}
                  mode={"success"}
                  type={"long"}
                  title={"Next"}
                  loading={isLoading}
                  disabled={
                    !accountNumber || !bankCode || !bankName || !accountHolder
                  }
                />
              </View>
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
);

export default AddKEAccount;
