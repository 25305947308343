import React, { useState } from "react";
import { SafeAreaView, ScrollView, View } from "react-native";
import {
  AppInput,
  Button,
  Header,
  Notification,
  tw,
  withModal,
} from "@shara-inc/design-system-mobile";
import { AuthHeader } from "../components/AuthHeader";
import { useLinkProps, useNavigation } from "@react-navigation/native";
import { ScreenProps } from "../App";
import Lock from "@shara-inc/design-system-mobile/assets/icons/components/Solid/Status/Lock";
import { createBusiness } from "../api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useToast } from "react-native-toast-notifications";

const AddBusiness: React.FC<ScreenProps<"AddBusiness">> = withModal(() => {
  const [businessName, setBusinessName] = useState<string>("");
  const [rcNumber, setRcNumber] = useState<string>("");
  const navigation = useNavigation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { onPress: handleNavigation } = useLinkProps({
    to: {
      screen: "AddAccount",
      params: {
        businessName,
        rcNumber,
      },
    },
  });
  const toast = useToast();

  const onSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("name", businessName);
      formData.append("address", "");
      formData.append("location", JSON.stringify({ lat: 1.22, lng: 3.44 }));

      //if the user already has a business data, don't create a new one
      const hasBusiness = await AsyncStorage.getItem("has-business");
      if (hasBusiness === null) {
        await createBusiness(formData);
      }

      setLoading(false);
      await AsyncStorage.removeItem("has-business");
      handleNavigation();
    } catch (error) {
      setLoading(false);
      toast.show((error as Error).message, {
        type: "danger",
      });
    }
  };

  return (
    <SafeAreaView style={tw`flex-1 bg-white`}>
      <Header
        onBackPress={() => {
          navigation.goBack();
        }}
        title={""}
      />
      <ScrollView style={tw`flex-1 mb-6`}>
        <View style={tw`items-center mt-2 flex-1 px-4`}>
          <AuthHeader
            title={
              "What’s the legal name/ Registered Company (RC) number of your business?"
            }
            description={
              "Please use the business name as seen on your company registration documents."
            }
            containerStyle={tw`mb-2`}
          />
          <View style={tw`flex-1 w-full`}>
            <AppInput
              label={"Business Name"}
              style={tw`mb-3`}
              onChangeText={(text) => setBusinessName(text)}
              maxLength={255}
            />
            <AppInput
              label={"RC Number"}
              onChangeText={(text) => setRcNumber(text)}
            />
          </View>
          <Notification
            message={
              "Your information will be encrypted, stored securely and is only used to verify your identity."
            }
            style={tw`mb-6 mt-6`}
            showIcon
            icon={<Lock width={12} color={"white"} />}
          />
        </View>
        <View style={tw`mx-4`}>
          <Button
            onPress={async () => {
              await onSubmit();
            }}
            mode={"success"}
            type={"long"}
            title={"Next"}
            disabled={!businessName || !rcNumber}
            loading={isLoading}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
});

export default AddBusiness;
