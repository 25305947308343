import React, { useEffect } from "react";
import HomeScreen from "./HomeScreen";
import TransactionsScreen from "./TransactionsScreen";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { tw, withModal } from "@shara-inc/design-system-mobile";
import { Platform } from "react-native";
import Download from "@shara-inc/design-system-mobile/assets/icons/components/Solid/Files/Download";
import DownloadOutline from "@shara-inc/design-system-mobile/assets/icons/components/Outline/Files/Download";
import Document from "@shara-inc/design-system-mobile/assets/icons/components/Solid/Files/Document";
import DocumentOutline from "@shara-inc/design-system-mobile/assets/icons/components/Outline/Files/Document";
import { usePinVerification } from "../lib/hooks";

export type MainTabParamsList = {
  Requests: undefined;
  History: undefined;
  Settings: undefined;
};

const MainTab = createBottomTabNavigator<MainTabParamsList>();

const MainScreen = withModal(({ openModal }) => {
  const { openCreatePinModal } = usePinVerification({ openModal });
  useEffect(() => {
    const pinSet = true;
    if (!pinSet) {
      openCreatePinModal();
    }
  }, [openCreatePinModal, openModal]);
  return (
    <MainTab.Navigator
      initialRouteName={"History"}
      screenOptions={{
        tabBarItemStyle: Platform.select({
          android: tw`py-2.5`,
          web: tw`py-2.5`,
        }),
        tabBarStyle: Platform.select({ android: tw`h-16`, web: tw`h-16` }),
        tabBarActiveTintColor: tw`text-brand-green-400`.color as string,
      }}
    >
      <MainTab.Screen
        name="History"
        component={TransactionsScreen}
        options={{
          headerShown: false,
          tabBarIcon: ({ size, color, focused }) =>
            focused ? (
              <Document width={size} height={size} color={color} />
            ) : (
              <DocumentOutline width={size} height={size} color={color} />
            ),
        }}
      />
    </MainTab.Navigator>
  );
});

export default MainScreen;
