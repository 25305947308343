import { useMemo } from "react";
import { RootState } from "../index";
import { useAppSelector } from "../hooks";

export const useAuth = () => {
  const auth = useAppSelector((state: RootState) => state.auth);
  return useMemo(
    () => ({ isLoggedIn: !!auth.token, user: auth.user }),
    [auth.token, auth.user]
  );
};
