import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ActivityIndicator, SafeAreaView, Text, View } from "react-native";
import {
  AppInput,
  Button,
  Header,
  Notification,
  tw,
  withModal,
  SelectInput,
} from "@shara-inc/design-system-mobile";
import { AuthHeader } from "../components/AuthHeader";
import {
  // StackActions,
  // useLinkProps,
  useNavigation,
} from "@react-navigation/native";
import Swiper from "react-native-web-swiper";
import Lock from "@shara-inc/design-system-mobile/assets/icons/components/Solid/Status/Lock";
// import Check from "@shara-inc/design-system-mobile/assets/icons/components/Solid/Interface/Check";
import { ScreenProps } from "../App";
import { userNameUpdate, verifyUserId } from "../api";
// import { accountNameLookup, fetchProviders } from "../api/disbursement";

const CreateAccountScreen: React.FC<ScreenProps<"CreateAccount">> = withModal(
  ({ route }) => {
    // const { onPress: handleNavigate } = useLinkProps({
    //   to: "",
    //   action: StackActions.replace("Main"),
    // });

    // const countryCode = "234";
    const countryCode = route.params.country_code;
    const [firstname, setFirstName] = useState<string>("");
    const [lastname, setLastName] = useState<string>("");
    const [businessName, setBusinessName] = useState<string>("");
    const [rcNumber, setRcNumber] = useState<string>("");
    // const [bankName, setBankName] = useState<string>("");
    const [bankAccount, setBankAccount] = useState<string>("");
    const [kenyanId, setKenyanId] = useState<string>("");
    const [, setMpesaNumber] = useState<string>("");
    const [, setMpesaType] = useState<string>("");
    const [bankCode, setBankCode] = useState<string>("");
    const [accountHolder] = useState<string>("");
    const [searchLoading] = useState<boolean>(false);

    // const [providers, setProviders] = useState([
    //   { label: "Select Bank", value: null },
    // ]);

    const [providers] = useState([
      { label: "Select Bank", value: null },
      {
        label: "Access Bank",
        value: "100013",
      },
      {
        label: "Fidelity Bank",
        value: "100019",
      },
      {
        label: "FCMB Easy Account",
        value: "100031",
      },
      {
        label: "First Bank of Nigeria",
        value: "000016",
      },
      {
        label: "Guaranty Trust Bank",
        value: "000013",
      },
      {
        label: "Union Bank",
        value: "000018",
      },
      {
        label: "United Bank for Africa",
        value: "000004",
      },
      {
        label: "Zenith Bank",
        value: "000015",
      },
    ]);
    const navigation = useNavigation();
    const swiperRef = useRef<Swiper | null>(null);

    useEffect(() => {
      (async () => {
        try {
          // const response = await fetchProviders();
          // const conc = [
          //   ...providers,
          //   ...response?.data?.disbursementProviders[1].fields[0].options,
          // ];
          // setProviders(conc);
          // setProviders((prov) => [
          //   ...prov,
          //   ...response?.data?.disbursementProviders[1].fields[0].options,
          // ]);
        } catch (error) {}
      })();
    }, []);

    const saveUserKYB = useCallback(async () => {
      await userNameUpdate({ firstname, lastname });
      if (countryCode === "234") {
        //save business
        //save disb method
      }

      if (countryCode === "254") {
        await verifyUserId({ idNumber: kenyanId, idType: "nid" });
      }
    }, [countryCode, firstname, kenyanId, lastname]);

    // const accountLookup = async () => {
    //   try {
    //     setSearchLoading(true);

    //     // const response = await accountNameLookup({
    //     //   nuban: bankAccount,
    //     //   bank_code: bankCode,
    //     // });
    //   } catch (error) {}
    // };

    // useEffect(() => {
    //   setBankAccount(bankAccount);
    // }, [bankAccount]);

    const forms = useMemo(() => {
      const list = [];
      list.push({
        form: (
          <View style={tw`items-center mt-2 flex-1 px-4`}>
            <AuthHeader
              title={"Create your account"}
              description={"We need these details to verify your identity."}
              containerStyle={tw`mb-2`}
            />
            <AppInput
              label={"First Name"}
              style={tw`mb-3`}
              defaultValue={firstname}
              onChangeText={(text) => setFirstName(text)}
            />
            <AppInput
              label={"Last Name"}
              defaultValue={lastname}
              onChangeText={(text) => setLastName(text)}
            />
          </View>
        ),
        onSubmit: async () => {
          if (!firstname || !lastname) {
            alert("All fields are required");
            return;
          }
          swiperRef.current.goToNext();
        },
      });
      if (countryCode === "234") {
        list.push(
          {
            form: (
              <View style={tw`items-center mt-2 flex-1 px-4`}>
                <AuthHeader
                  title={
                    "What’s the legal name/ Registered Company (RC) number of your business?"
                  }
                  description={
                    "Please use the business name as seen on your company registration documents."
                  }
                  containerStyle={tw`mb-2`}
                />
                <View style={tw`flex-1 w-full`}>
                  <AppInput
                    label={"Business Name"}
                    style={tw`mb-3`}
                    onChangeText={(text) => setBusinessName(text)}
                  />
                  <AppInput
                    label={"RC Number"}
                    onChangeText={(text) => setRcNumber(text)}
                  />
                </View>
                <Notification
                  message={
                    "Your information will be encrypted, stored securely and is only used to verify your identity."
                  }
                  style={tw`mb-6`}
                  showIcon
                  icon={<Lock width={12} color={"white"} />}
                />
              </View>
            ),
            onSubmit: async () => {
              if (!businessName || !rcNumber) {
                alert("All fields are required");
                return;
              }
              swiperRef.current.goToNext();
            },
          },
          {
            form: (
              <View style={tw`items-center mt-2 flex-1 px-4`}>
                <AuthHeader
                  title={"Enter your bank account details"}
                  description={
                    "Account details are only required for the disbursement of the funds into your account."
                  }
                  containerStyle={tw`mb-2`}
                />
                <View style={tw`flex-1 w-full`}>
                  <AppInput
                    label={"Bank Account Number"}
                    onChangeText={(text) => {
                      setBankAccount(text);
                      if (text.length === 10) {
                        // accountLookup();
                      }
                    }}
                    style={tw`mb-3`}
                  />
                  <SelectInput
                    label={"Bank Name"}
                    //@ts-ignore
                    onValueChange={(text) => {
                      //@ts-ignore
                      setBankCode(text);
                      // if (bankAccount && text) {
                      // accountLookup();
                      // }
                    }}
                    options={providers}
                  />
                  <View style={tw`flex-row align-items mt-4`}>
                    {searchLoading && <ActivityIndicator size={"small"} />}
                    {accountHolder && (
                      <Text style={tw`ml-4`}>{accountHolder}</Text>
                    )}
                  </View>
                </View>
                <Notification
                  message={
                    "Your information will be encrypted, stored securely and is only used to verify your identity."
                  }
                  style={tw`mb-6`}
                  showIcon
                  icon={<Lock width={12} color={"white"} />}
                />
              </View>
            ),
            onSubmit: async () => {
              if (!bankAccount || !bankCode) {
                alert("All fields are required");
                return;
              }
              // const closeLoadingModal = openModal("loading", {
              //   text: "Just a moment while we update your information",
              // });
              await saveUserKYB();
              // setTimeout(() => {
              //   closeLoadingModal();
              //   const closeMessageModal = openModal("message", {
              //     title: "Registration Successful",
              //     icon: (
              //       <Check
              //         width={40}
              //         height={40}
              //         color={tw`text-[#212121]`.color as string}
              //       />
              //     ),
              //     containerStyle: tw`bg-[#212121]`,
              //     buttonProps: {
              //       title: "Home",
              //       onPress: () => {
              //         closeMessageModal();
              //         handleNavigate();
              //       },
              //     },
              //   });
              // }, 2000);
            },
          }
        );
      } else if (countryCode === "254") {
        list.push(
          {
            form: (
              <View style={tw`items-center mt-2 flex-1 px-4`}>
                <AuthHeader
                  title={"Enter your ID Number"}
                  description={"Confirm all your details below are correct."}
                  containerStyle={tw`mb-2`}
                />
                <View style={tw`flex-1 w-full`}>
                  <AppInput
                    placeholder={"ID Number"}
                    onChangeText={(text) => setKenyanId(text)}
                  />
                </View>
                <Notification
                  message={
                    "Your information will be encrypted, stored securely and is only used to verify your identity."
                  }
                  style={tw`mb-6`}
                  showIcon
                  icon={<Lock width={12} color={"white"} />}
                />
              </View>
            ),
            onSubmit: async () => {
              swiperRef.current.goToNext();
            },
          },
          {
            form: (
              <View style={tw`items-center mt-2 flex-1 px-4`}>
                <AuthHeader
                  title={"M-Pesa Pay Bill or Till"}
                  description={"We will send approved loans to this account."}
                  containerStyle={tw`mb-2`}
                />
                <View style={tw`flex-1 w-full`}>
                  <SelectInput
                    label={"Select M-Pesa Pay Bill or Buy Goods"}
                    containerStyle={tw`mb-3`}
                    options={[
                      { label: "M-Pesa Pay Bill", value: "pay-bill" },
                      { label: "M-Pesa Buy Goods", value: "buy-goods" },
                    ]}
                    //@ts-ignore
                    onValueChange={(text) => setMpesaType(text)}
                  />
                  <AppInput
                    label={"Enter Pay Bill Number"}
                    placeholder={"Pay Bill Number"}
                    onChangeText={(text) => setMpesaNumber(text)}
                  />
                </View>
                <Notification
                  message={
                    "Your information will be encrypted, stored securely and is only used to verify your identity."
                  }
                  style={tw`mb-6`}
                  showIcon
                  icon={<Lock width={12} color={"white"} />}
                />
              </View>
            ),
            onSubmit: async () => {
              // const closeLoadingModal = openModal("loading", {
              //   text: "Just a moment while we update your information",
              // });
              await saveUserKYB();
              // setTimeout(() => {
              //   closeLoadingModal();
              //   const closeMessageModal = openModal("message", {
              //     title: "Registration Successful",
              //     icon: (
              //       <Check
              //         width={40}
              //         height={40}
              //         color={tw`text-[#212121]`.color as string}
              //       />
              //     ),
              //     containerStyle: tw`bg-[#212121]`,
              //     buttonProps: {
              //       title: "Home",
              //       onPress: () => {
              //         closeMessageModal();
              //         handleNavigate();
              //       },
              //     },
              //   });
              // }, 2000);
            },
          }
        );
      }
      return list;
    }, [
      firstname,
      lastname,
      countryCode,
      providers,
      searchLoading,
      accountHolder,
      businessName,
      rcNumber,
      bankAccount,
      bankCode,
      saveUserKYB,
    ]);

    return (
      <SafeAreaView style={tw`flex-1 bg-white`}>
        <Header
          onBackPress={() => {
            const activeIndex = swiperRef.current.getActiveIndex();
            if (activeIndex === 0) {
              navigation.goBack();
            } else {
              swiperRef.current.goToPrev();
            }
          }}
          title={""}
        />
        <View style={tw`flex-1 mb-6`}>
          <Swiper
            ref={swiperRef}
            controlsEnabled={false}
            gesturesEnabled={() => false}
          >
            {forms.map(({ form }) => form)}
          </Swiper>
          <View style={tw`px-4`}>
            <Button
              onPress={async () => {
                const activeIndex = swiperRef.current.getActiveIndex();
                const { onSubmit } = forms[activeIndex];
                await onSubmit();
              }}
              mode={"success"}
              type={"long"}
              title={"Next"}
            />
          </View>
        </View>
      </SafeAreaView>
    );
  }
);

export default CreateAccountScreen;
