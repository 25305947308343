import {
  applyMiddleware,
  configureStore,
  StoreEnhancer,
} from "@reduxjs/toolkit";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { persistStore, persistReducer } from "redux-persist";
import authReducer, { AuthState } from "./auth/slice";
import { logger } from "redux-logger";

const authPersistConfig = {
  key: "auth",
  storage: AsyncStorage,
};

const enhancers: StoreEnhancer[] = [];
if (process.env.NODE_ENV === "development") {
  enhancers.push(applyMiddleware(logger));
}

export const store = configureStore({
  reducer: {
    auth: persistReducer<AuthState>(authPersistConfig, authReducer),
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false });
  },
  enhancers,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
