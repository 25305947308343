import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ListRenderItem,
  Pressable,
  RefreshControl,
  SafeAreaView,
  SectionList,
  Text,
  View,
  ActivityIndicator,
} from "react-native";
import {
  // Button,
  Card,
  Notification,
  tw,
  withModal,
} from "@shara-inc/design-system-mobile";
// import Document from "@shara-inc/design-system-mobile/assets/icons/components/Solid/Files/Document";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import _ from "lodash";
import { format } from "date-fns";
import { AppHeader } from "../components/AppHeader";
import { Drawdown, fetchDrawdowns } from "../api/drawdowns";
import { useIsFocused } from "@react-navigation/native";
import { ReceiptImage } from "../components/ReceiptImage";
import Cancel from "@shara-inc/design-system-mobile/assets/icons/components/Outline/Interface/Cancel";
import Clock from "@shara-inc/design-system-mobile/assets/icons/components/Outline/General/Clock";

const TransactionsScreen = withModal(({ openModal }) => {
  const [transactions, setTransactions] = useState<Drawdown[]>([]);
  const [refreshing, setRefreshing] = useState(false);
  const isFocused = useIsFocused();

  const filteredTranx = transactions.filter(
    (item) => item?.status !== "pending"
  );

  const sortFilteredTranx = filteredTranx.sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );

  useEffect(() => {
    (async () => {
      setRefreshing(true);
      await fetchOtherDrawdowns();
      setRefreshing(false);
    })();
  }, [isFocused]);

  const fetchOtherDrawdowns = async () => {
    try {
      const response = await fetchDrawdowns();
      //@ts-ignore
      setTransactions(response.data?.drawdowns);
    } catch (error) {}
  };

  const onRefresh = async () => {
    setRefreshing(true);
    await fetchOtherDrawdowns();
    setRefreshing(false);
  };

  const sections = useMemo(() => {
    return _(sortFilteredTranx)
      .groupBy((transaction) => {
        return format(new Date(transaction.created_at), "MMMM yyy");
      })
      .mapValues((data, title) => {
        return {
          title,
          data,
        };
      })
      .values()
      .value();
  }, [sortFilteredTranx]);

  const sortDisbursementsById = (
    data: Array<{
      amount: number;
      currency_code: string;
      id: number;
      provider_label: string;
      status: "failed" | "pending" | "success" | "queued" | "processing";
    }>
  ) => {
    return data.sort((a, b) => b.id - a.id);
  };

  const setCardIcon = useCallback((item: Drawdown) => {
    if (item.status === "cancelled") {
      return (
        <Cancel
          width={20}
          height={20}
          color={tw.style("text-gray-500").color as string}
        />
      );
    }

    if (item?.disbursements?.length > 0) {
      const disbursementData = sortDisbursementsById(item?.disbursements)[0];

      if (disbursementData?.status === "failed") {
        return (
          <MaterialCommunityIcons
            name={"close"}
            size={16}
            color={tw.style("text-brand-red-300").color as string}
          />
        );
      }

      if (
        disbursementData?.status === "pending" ||
        disbursementData?.status === "queued" ||
        disbursementData?.status === "processing"
      ) {
        return (
          <Clock
            width={20}
            height={20}
            color={tw.style("text-gray-500").color as string}
          />
        );
      }

      if (disbursementData?.status === "success") {
        return (
          <MaterialCommunityIcons
            name={"check"}
            size={16}
            color={tw.style("text-brand-green-300").color as string}
          />
        );
      }
    }

    return (
      <Clock
        width={20}
        height={20}
        color={tw.style("text-gray-500").color as string}
      />
    );
  }, []);

  const setCardStatus = useCallback((item: Drawdown) => {
    if (item.status === "cancelled") {
      return "Cancelled";
    }

    if (item?.disbursements?.length > 0) {
      const disbursementData = sortDisbursementsById(item?.disbursements)[0];
      if (disbursementData?.status === "failed") {
        return "Failed";
      }

      if (
        disbursementData?.status === "pending" ||
        disbursementData?.status === "queued" ||
        disbursementData?.status === "processing"
      ) {
        return "Pending...";
      }

      if (disbursementData?.status === "success") {
        return "Success";
      }
    }

    return "Pending...";
  }, []);

  const renderTransactionListItem = useCallback<ListRenderItem<Drawdown>>(
    ({ item }) => {
      return (
        <Pressable
          onPress={() => {
            const closeReceiptModal = openModal("full", {
              renderContent: () => {
                return (
                  <ReceiptImage
                    transaction={{
                      customer: {
                        name: `${item?.user?.firstname}`,
                      },
                      drawdown_id: item?.id,
                    }}
                    onClose={closeReceiptModal}
                  />
                );
              },
            });
          }}
        >
          <Card
            leftChild={setCardIcon(item)}
            rightChild={
              <Card
                title={`${setCardStatus(item) === "Success" ? "+" : ""}${
                  item?.currency_code === "NGN" ? "₦" : "Ksh"
                }${item?.amount_drawn}`}
                titleStyle={tw`font-bold text-sm mb-0.5`}
                contentContainerStyle={tw`items-end`}
                containerStyle={tw`py-1.5 pr-0`}
              />
            }
            containerStyle={tw`bg-white py-2 border-b border-gray-300 px-4`}
            title={`From ${item?.user?.firstname}`}
            titleStyle={tw`font-bold text-sm mb-1`}
            description={
              <Text style={tw`text-xs text-gray-500`}>
                {format(new Date(item?.created_at), "MMMM do")} •{" "}
                <Text
                  style={tw`${
                    setCardStatus(item) === "Failed"
                      ? "text-brand-red-300"
                      : "text-gray-500"
                  }`}
                >
                  {setCardStatus(item)}
                </Text>
              </Text>
            }
            descriptionStyle={tw`text-xs text-gray-200`}
          />
        </Pressable>
      );
    },
    [openModal, setCardIcon, setCardStatus]
  );

  return (
    <SafeAreaView style={tw`flex-1`}>
      <AppHeader />
      {/* {!!transactions.length && (
        <Button
          onPress={async () => {}}
          mode={"default"}
          type={"long"}
          title={"Request Statement"}
          textStyle={tw`text-brand-blue-link`}
          containerStyle={tw`rounded-0 py-4`}
          iconLeft={
            <Document width={20} color={tw`text-brand-blue-link`.color} />
          }
        />
      )} */}
      {refreshing && <ActivityIndicator size={"small"} style={tw`mt-10`} />}
      {!refreshing && (
        <SectionList
          sections={sections}
          contentContainerStyle={tw`flex-1`}
          renderSectionHeader={({ section: { title } }) => (
            <View
              style={tw`px-4 py-2 bg-gray-200 border-t border-b border-gray-300`}
            >
              <Text style={tw`text-xs font-500`}>{title}</Text>
            </View>
          )}
          renderItem={renderTransactionListItem}
          ListEmptyComponent={
            <View style={tw`flex-1 h-full justify-center p-4`}>
              <Notification
                message={
                  "You have no transactions.\n" +
                  "Ask your buyer to send you a transaction to get started."
                }
                mode={"neutral"}
                style={tw`py-2.5`}
              />
            </View>
          }
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
          refreshing={refreshing}
        />
      )}
    </SafeAreaView>
  );
});

export default TransactionsScreen;
