import React from "react";
import { Text, View, ViewStyle } from "react-native";
import { tw } from "@shara-inc/design-system-mobile";

export type AuthHeaderProps = {
  title: string;
  description: string | React.ReactNode;
  containerStyle?: ViewStyle;
};

export const AuthHeader = ({
  title,
  description,
  containerStyle,
}: AuthHeaderProps) => {
  return (
    <View style={tw.style(`items-center px-2`, containerStyle)}>
      <Text style={tw`text-lg text-center font-500 mb-2.5`}>{title}</Text>
      <Text style={tw`mb-4 text-center text-base text-gray-600`}>
        {description}
      </Text>
    </View>
  );
};
