import React, { useEffect } from "react";
import { LinkingOptions, NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import SplashScreen from "./screens/SplashScreen";
import MainScreen from "./screens/MainScreen";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider as StoreProvider } from "react-redux";
import { useAuth } from "./store/auth/hooks";
import LogInScreen from "./screens/LogInScreen";
import OtpVerificationScreen from "./screens/OtpVerificationScreen";
import CreateAccountScreen from "./screens/CreateAccountScreen";
import { StatusBar } from "react-native";
import { RouteProp } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import { useAppDispatch } from "./store/hooks";
import { setToken, updateUserState } from "./store/auth/slice";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useFonts } from "expo-font";
import UpdateUserName from "./screens/UpdateUserName";
import AddBusiness from "./screens/AddBusiness";
import AddAccount from "./screens/AddAccount";
import VerifyId from "./screens/VerifyId";
import AddKEAccount from "./screens/AddKEAccount";
import { ToastProvider } from "react-native-toast-notifications";

export type AppStackParamList = {
  App: undefined;
  Splash: undefined;
  LogIn: undefined;
  OtpVerification: any;
  CreateAccount: { mobile: string; country_code: string };
  AddBusiness: undefined;
  UpdateUserName: undefined;
  AddAccount: { businessName: string; rcNumber: string };
  VerifyId: undefined;
  AddKEAccount: undefined;
  History: undefined;
};

type ScreenNavigationProp<T extends keyof AppStackParamList> =
  StackNavigationProp<AppStackParamList, T>;

type ScreenRouteProp<T extends keyof AppStackParamList> = RouteProp<
  AppStackParamList,
  T
>;

export type ScreenProps<T extends keyof AppStackParamList> = {
  route: ScreenRouteProp<T>;
  navigation: ScreenNavigationProp<T>;
};

const AppStack = createNativeStackNavigator<AppStackParamList>();
const MainStack = createNativeStackNavigator<AppStackParamList>();

const linking: LinkingOptions<AppStackParamList> = {
  prefixes: [],
  config: {
    screens: {
      Splash: "get-started/:supplier-mobile?/:invite-code?",
      LogIn: "login",
      OtpVerification: "verify-otp/:mobile?",
      CreateAccount: "create-account",
      AddBusiness: "add-business",
      UpdateUserName: "update-user",
      AddAccount: "add-account",
      VerifyId: "verify-id",
      AddKEAccount: "account-details",
      App: {
        screens: {
          Home: "home",
        },
      },
    },
  },
};

const AuthScreensNavigator = () => (
  <AppStack.Navigator initialRouteName="Splash">
    <AppStack.Screen
      name="Splash"
      component={SplashScreen}
      options={{ headerShown: false }}
    />
    <AppStack.Screen
      name="LogIn"
      component={LogInScreen}
      options={{ headerShown: false }}
    />
    <AppStack.Screen
      name="OtpVerification"
      component={OtpVerificationScreen}
      options={{ headerShown: false }}
    />
    <AppStack.Screen
      name="CreateAccount"
      component={CreateAccountScreen}
      options={{ headerShown: false }}
    />
    <AppStack.Screen
      name="UpdateUserName"
      component={UpdateUserName}
      options={{ headerShown: false }}
    />
    <AppStack.Screen
      name="AddBusiness"
      component={AddBusiness}
      options={{ headerShown: false }}
    />
    <AppStack.Screen
      name="AddAccount"
      component={AddAccount}
      options={{ headerShown: false }}
    />
    <AppStack.Screen
      name="VerifyId"
      component={VerifyId}
      options={{ headerShown: false }}
    />
    <AppStack.Screen
      name="AddKEAccount"
      component={AddKEAccount}
      options={{ headerShown: false }}
    />
  </AppStack.Navigator>
);

const MainScreensNavigator = () => (
  <MainStack.Navigator initialRouteName="App">
    <MainStack.Screen
      name="App"
      component={MainScreen}
      options={{ headerShown: false }}
    />
  </MainStack.Navigator>
);

const RootStack = createNativeStackNavigator();
const RootStackNavigator = ({ isLoggedIn }) => (
  <RootStack.Navigator
    screenOptions={{
      headerShown: false,
    }}
  >
    {!isLoggedIn ? (
      <RootStack.Screen name="auth" component={AuthScreensNavigator} />
    ) : (
      <RootStack.Screen name="app" component={MainScreensNavigator} />
    )}
  </RootStack.Navigator>
);

const App = () => {
  // TODO: Add Font loading code to design system and import
  useFonts({
    "Roboto-Regular": require("@shara-inc/design-system-mobile/assets/fonts/Roboto-Regular.otf"),
    "Roboto-Medium": require("@shara-inc/design-system-mobile/assets/fonts/Roboto-Medium.otf"),
    "Roboto-Bold": require("@shara-inc/design-system-mobile/assets/fonts/Roboto-Bold.otf"),
    "Hellix-Regular": require("@shara-inc/design-system-mobile/assets/fonts/Hellix-Regular.ttf"),
    "Hellix-Bold": require("@shara-inc/design-system-mobile/assets/fonts/Hellix-Bold.ttf"),
  });

  const { isLoggedIn } = useAuth();

  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      const userDetails = await AsyncStorage.getItem("userDetails");
      if (userDetails) {
        const storedUserState = JSON.parse(userDetails);
        dispatch(setToken(storedUserState.token));
        dispatch(updateUserState(storedUserState.user));
      }
    })();
  }, [dispatch]);

  return (
    <NavigationContainer linking={linking}>
      <ToastProvider
        placement="top"
        duration={5000}
        animationType="slide-in"
        animationDuration={250}
        dangerColor={"#DB5B5B"}
        style={{ elevation: 10 }}
        swipeEnabled={true}
      >
        <RootStackNavigator isLoggedIn={isLoggedIn} />
      </ToastProvider>
    </NavigationContainer>
  );
};

export default () => (
  <>
    <StatusBar />
    <StoreProvider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </StoreProvider>
  </>
);
