import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import {
  ApiResponse,
  User,
  UserCheckPayload,
  VerifyOtpPayload,
} from "../../types/app";
import { axiosInstance, baseURL } from "../config";

const forgeUserData = (apiResponse: any) => {
  try {
    const coreUserData = apiResponse.data;
    const userDetails: User = {
      id: coreUserData?.user?.id,
      firstname: coreUserData?.user?.firstname,
      lastname: coreUserData?.user?.lastname,
      mobile: coreUserData?.user?.mobile,
      email: coreUserData?.user?.email,
      country_code: coreUserData?.user?.country_code,
      is_identity_verified: coreUserData.user.is_identity_verified,
      wallet: coreUserData?.wallet,
      business: coreUserData?.business,
    };
    return userDetails;
  } catch (error) {}
};

type OtpPayload = {
  country_code: string;
  mobile: string;
  auth_type: string;
  merchant_invite_code?: string;
};

type LoginPayload = {
  mobile: string;
  password: string;
};

type VeryIDPayload = {
  idNumber: string;
  idType: string;
};

export const logIn = async (data: LoginPayload) => {
  const response = await axiosInstance.post<null, ApiResponse<{}>>(
    "/auth/login",
    data
  );

  const userDetails = forgeUserData(response);
  return {
    user: userDetails,
    //@ts-ignore
    token: response.data?.credentials?.token,
    //@ts-ignore
    hasDisbursementMethods: response?.data?.hasDisbursementMethods,
  };
};

export const sendOtp = (data: OtpPayload) => {
  return axiosInstance.post<null, ApiResponse<{}>>("/auth/otp", data);
};

export const signUp = (data: {}) => {
  return axiosInstance.post<null, ApiResponse<{}>>("/auth/register", data);
};

export const userCheck = async (data: UserCheckPayload): Promise<any> => {
  return axiosInstance.post<null, ApiResponse<{}>>("/users/check", data);
};

export const verifyOtp = async (data: VerifyOtpPayload): Promise<any> => {
  const fetchResponse = await axiosInstance.post<null, ApiResponse<{}>>(
    "/a/login",
    data
  );
  const userDetails = forgeUserData(fetchResponse);
  await AsyncStorage.setItem(
    "userDetails",
    JSON.stringify({
      user: userDetails,
      //@ts-ignore
      token: fetchResponse.data?.credentials?.token,
    })
  );
  //@ts-ignore
  return { user: userDetails, token: fetchResponse.data?.credentials?.token };
};

export const userNameUpdate = async (
  data: Pick<User, "firstname" | "lastname">
) => {
  const token = await AsyncStorage.getItem("user-token");
  return await axios.patch(`${baseURL}/users/me`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const verifyUserId = async (payload: VeryIDPayload): Promise<any> => {
  const token = await AsyncStorage.getItem("user-token");
  return axios.put<null, ApiResponse<{}>>(
    `${baseURL}/identity/verify`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const createBusiness = async (payload: FormData): Promise<any> => {
  const token = await AsyncStorage.getItem("user-token");
  const response = await axios.post(`${baseURL}/business`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type":
        "multipart/form-data; boundary=<calculated when request is sent>",
    },
  });

  return response.data;
};

export const updateKyc = async (data: {
  kycId: string;
  businessName?: string;
  rcNumber?: string;
  business_account_details?: string;
  national_id?: string;
  firstname?: string;
  lastname?: string;
}): Promise<any> => {
  const token = await AsyncStorage.getItem("user-token");
  await axios.patch(
    `${baseURL}/bnpl/kyc/${data?.kycId}/update-kyc`,
    {
      business_name: data?.businessName,
      business_rc_number: data?.rcNumber,
      business_account_details: data?.business_account_details,
      national_id: data?.national_id,
      firstname: data?.firstname,
      lastname: data?.lastname,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const fetchUserKyc = async (): Promise<any> => {
  const token = await AsyncStorage.getItem("user-token");
  const id = await AsyncStorage.getItem("user-id");
  const response = await axios.get(`${baseURL}/bnpl/kyc?user_id=${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return response?.data;
};

export const createKyc = async (): Promise<any> => {
  const id = await AsyncStorage.getItem("user-id");

  return axiosInstance.post<null, ApiResponse<{}>>("/bnpl/kyc", {
    user_id: Number(id),
    kyc_data_source: "supplier-app",
  });
};

export const queueSupplierComms = async (data: {
  status: boolean;
}): Promise<any> => {
  const id = await AsyncStorage.getItem("user-id");

  return axiosInstance.post<null, ApiResponse<{}>>(
    "/queue/supplier-registration-comms/process",
    {
      data: {
        isSuccess: data.status,
        supplier_id: id,
      },
      type: "bcl::supplier-registration-comms",
    }
  );
};

export const fetchSupplierApproval = async (): Promise<any> => {
  const token = await AsyncStorage.getItem("user-token");
  const response = await axios.get(`${baseURL}/bcl/suppliers/me/approval`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
