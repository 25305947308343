import React, { useEffect, useState } from "react";
import { View, SafeAreaView } from "react-native";
import {
  Button,
  tw,
  PhoneNumberField,
  PhoneNumber,
  Header,
} from "@shara-inc/design-system-mobile";
import { AuthHeader } from "../components/AuthHeader";
import { sendOtp } from "../api";
import { useLinkProps, useNavigation } from "@react-navigation/native";
import { useToast } from "react-native-toast-notifications";

const LogInScreen = () => {
  const navigation = useNavigation();
  const [mobile, setMobile] = useState<PhoneNumber>();
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();

  const { onPress: handleOtp } = useLinkProps({
    to: {
      screen: "OtpVerification",
      params: {
        mobile: mobile?.number,
        country_code: mobile?.callingCode,
      },
    },
  });

  const handleSendOtp = async () => {
    setLoading(true);
    try {
      let cleanMobile = mobile?.number.trim();
      if (mobile?.number.startsWith("0")) {
        cleanMobile = mobile?.number.substring(1);
      }
      const newMobileString = `${mobile?.callingCode}${cleanMobile}`.replace(
        /\s/g,
        ""
      );

      const modifiedUser = {
        country_code: mobile?.callingCode,
        mobile: newMobileString,
        auth_type: "supplier",
      };

      await sendOtp(modifiedUser);
      setLoading(false);

      handleOtp();
    } catch (error) {
      setLoading(false);
      toast.show((error as Error).message, {
        type: "danger",
      });
    }
  };

  useEffect(() => {
    if (mobile?.number?.length > 9) {
    } else {
    }
  }, [mobile?.callingCode, mobile?.number?.length]);

  const onChangeMobile = (value: PhoneNumber) => {
    setMobile(value);
  };

  return (
    <SafeAreaView style={tw`flex-1 bg-white`}>
      <Header onBackPress={navigation.goBack} title={""} />
      <View style={tw`flex-1 px-4 mb-6`}>
        <View style={tw`items-center mt-2 flex-1`}>
          <AuthHeader
            title={"Enter your Phone Number"}
            description={
              "We’ll confirm your number by sending you 6 digit code"
            }
            containerStyle={tw`mb-2`}
          />
          <PhoneNumberField
            value={{ number: mobile?.number, callingCode: mobile?.callingCode }}
            onChangeText={(data) => onChangeMobile(data)}
            callingCodeValue={"234"}
            maxLength={10}
            keyboardType={"number-pad"}
          />
        </View>
        <Button
          onPress={() => handleSendOtp()}
          mode={"success"}
          type={"long"}
          title={"Next"}
          disabled={mobile?.number?.length < 9 || !mobile}
          loading={loading}
        />
      </View>
    </SafeAreaView>
  );
};

export default LogInScreen;
