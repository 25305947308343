import axios from "axios";
import Config from "react-native-config";
import { store } from "../store";

export const baseURL = Config.API_BASE_URL;

export const axiosInstance = axios.create({
  baseURL,
  alertSuccess: true,
  alertError: true,
});

axiosInstance.interceptors.request.use((config) => {
  const token = store.getState().auth.token;
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response) {
      const {
        response: { data },
        config: { alertError },
      } = error;
      if (data) {
        if (data.message && alertError) {
          console.log("An error has occurred\n" + data.message);
        }
        throw data;
      }
    }
    throw error;
  }
);
