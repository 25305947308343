import React, { useEffect, useState } from "react";
import { SafeAreaView, View } from "react-native";
import {
  AppInput,
  Button,
  Header,
  Notification,
  tw,
  withModal,
} from "@shara-inc/design-system-mobile";
import { AuthHeader } from "../components/AuthHeader";
import { useLinkProps, useNavigation } from "@react-navigation/native";
import { ScreenProps } from "../App";
import Lock from "@shara-inc/design-system-mobile/assets/icons/components/Solid/Status/Lock";
import { createKyc, fetchUserKyc, updateKyc, verifyUserId } from "../api";
import { useToast } from "react-native-toast-notifications";

const VerifyId: React.FC<ScreenProps<"VerifyId">> = withModal(() => {
  const [kenyanId, setKenyanId] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const toast = useToast();
  const [kycId, setKycId] = useState<string>("");

  const { onPress: handleNavigation } = useLinkProps({
    to: {
      screen: "AddKEAccount",
    },
  });

  const navigation = useNavigation();

  const createKYC = async () => {
    try {
      await createKyc();
    } catch (error) {}
  };

  const fetchKYC = async () => {
    try {
      const response = await fetchUserKyc();
      setKycId(response?.data[0].id);
    } catch (error) {}
  };

  useEffect(() => {
    createKYC();
    fetchKYC();
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    try {
      await verifyUserId({
        idNumber: kenyanId,
        idType: "National_ID",
      });
      await updateKyc({
        kycId,
        national_id: kenyanId,
      });
      setLoading(false);
      handleNavigation();
    } catch (error) {
      toast.show(error?.response?.data.message ?? "Error\nPlease try again", {
        type: "danger",
      });
      setLoading(false);
    }
  };

  return (
    <SafeAreaView style={tw`flex-1 bg-white`}>
      <Header
        onBackPress={() => {
          navigation.goBack();
        }}
        title={""}
      />
      <View style={tw`flex-1 mb-6`}>
        <View style={tw`items-center mt-2 flex-1 px-4`}>
          <AuthHeader
            title={"Enter your ID Number"}
            description={"Confirm all your details below are correct."}
            containerStyle={tw`mb-2`}
          />
          <View style={tw`flex-1 w-full`}>
            <AppInput
              placeholder={"ID Number"}
              defaultValue={kenyanId}
              onChangeText={(text) => setKenyanId(text)}
              keyboardType={"number-pad"}
            />
          </View>
          <Notification
            message={
              "Your information will be encrypted, stored securely and is only used to verify your identity."
            }
            style={tw`mb-6`}
            showIcon
            icon={<Lock width={12} color={"white"} />}
          />
        </View>
      </View>
      <View style={tw`m-4`}>
        <Button
          onPress={async () => {
            await onSubmit();
          }}
          mode={"success"}
          type={"long"}
          title={"Next"}
          disabled={kenyanId.length < 8}
          loading={isLoading}
        />
      </View>
    </SafeAreaView>
  );
});

export default VerifyId;
