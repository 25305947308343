import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Linking,
  ActivityIndicator,
} from "react-native";
import { tw } from "@shara-inc/design-system-mobile";
import LinkButton from "../components/LinkButton";
import { LinearGradient } from "expo-linear-gradient";
import ArrowRight from "@shara-inc/design-system-mobile/assets/icons/components/Outline/Interface/ArrowRight";
import { sendOtp } from "../api";
import { useLinkProps } from "@react-navigation/native";
import { useToast } from "react-native-toast-notifications";

const SplashScreen = () => {
  const [webUrl, setWebUrl] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [inviteCode, setCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const toast = useToast();

  Linking.getInitialURL().then((url) => {
    setWebUrl(url);
  });

  const { onPress: handleOtp } = useLinkProps({
    to: {
      screen: "OtpVerification",
      params: {
        mobile: mobile.substring(3),
        country_code: mobile.substring(0, 3),
      },
    },
  });

  useEffect(() => {
    (async function () {
      if (webUrl.length > 0 && webUrl.includes("?") && webUrl.includes("&")) {
        var firstSplit = webUrl?.split("?")[1].split("&");
        for (let index = 0; index < firstSplit.length; index++) {
          let secondSplit = firstSplit[index].split("=");
          index === 0 ? setMobile(secondSplit[1]) : setCode(secondSplit[1]);
        }
        if (mobile && inviteCode) {
          const country_code = mobile.substring(0, 3);
          const phoneNumber = mobile.substring(3);

          const userData = {
            country_code,
            mobile: phoneNumber,
            auth_type: "supplier",
            merchant_invite_code: inviteCode,
          };
          try {
            await sendOtp(userData);
            handleOtp();
          } catch (error) {
            setLoading(false);
            toast.show((error as Error).message, {
              type: "danger",
            });
          }
          setLoading(false);
        }
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleOtp, inviteCode, mobile, webUrl]);

  const windowWidth = Math.min(Dimensions.get("window").width, 600);
  const imageHeight = (589 / 512) * windowWidth;

  if (loading) {
    return (
      <View style={tw.style("flex-1 bg-[#FFFFFF] items-center justify-center")}>
        <ActivityIndicator size={"large"} />
      </View>
    );
  }

  return (
    <View style={tw`flex-1 max-w-[${windowWidth}px] mx-auto relative bg-black`}>
      <Image
        source={require("../assets/images/happy-shara-merchant.jpg")}
        style={tw`w-full h-[${imageHeight}px] absolute`}
        resizeMode="contain"
      />
      <LinearGradient
        colors={["transparent", "#000"]}
        locations={[0, 0.6]}
        style={tw`items-center flex-col-reverse flex-1 px-6 pb-10 mt-[12%] z-1`}
      >
        {/*
          <LinkButton
            to={"/get-started"}
            textStyle={tw`text-white`}
            title="Learn More"
            type={"long"}
            inverted
            containerStyle={tw`mt-4`}
          />
        */}
        <LinkButton
          to={"/login"}
          title="Get Started"
          mode={"default"}
          type={"long"}
          containerStyle={tw`mt-8`}
          iconRight={<ArrowRight width={20} height={20} color={"black"} />}
        />
        <Text style={tw`text-white text-lg mt-6 text-center`}>
          Shara settles you upfront and allows your customers to pay over time.
          We&apos;ll only ask for a few details to verify your identity.
        </Text>
        <Text
          style={tw`text-white text-3xl font-bold text-center font-display-bold`}
        >
          Let’s get started!
        </Text>
      </LinearGradient>
    </View>
  );
};

export default SplashScreen;
