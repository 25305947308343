export const checkAboutSectionCompletion = (about: any) => {
  const properties: (
    | "national_id_photo"
    | "dob"
    | "lastname"
    | "firstname"
    | "national_id"
    | "business_name"
  )[] = [
    "national_id_photo",
    "dob",
    "lastname",
    "firstname",
    "national_id",
    "business_name",
  ];

  let aboutSectionComplete = true;

  const aboutSection = {
    national_id_photo: false,
    firstname: false,
    dob: false,
    lastname: false,
    national_id: false,
    aboutSectionComplete: false,
    business_name: false,
  };

  properties?.forEach(
    (
      each:
        | "national_id_photo"
        | "dob"
        | "lastname"
        | "firstname"
        | "national_id"
        | "business_name"
    ) => {
      const aboutDetails: any = about?.[each];

      if (aboutDetails) {
        if (
          aboutDetails?.length &&
          ![
            "national_id_photo",
            "dob",
            "lastname",
            "firstname",
            "national_id",
            "business_name",
          ].includes(each)
        ) {
          aboutSection[each] = true;
        } else {
          aboutSection[each] = true;
        }
      } else {
        if (
          [
            "national_id_photo",
            "dob",
            "lastname",
            "firstname",
            "national_id",
            "business_name",
          ].includes(each)
        ) {
          aboutSectionComplete = false;
        }
        aboutSection[each] = false;
      }
    }
  );

  aboutSection.aboutSectionComplete = aboutSectionComplete;

  return aboutSection;
};
