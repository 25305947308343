import React, { useCallback } from "react";
import { OpenModal, tw } from "@shara-inc/design-system-mobile";
import { Text, View } from "react-native";
import Check from "@shara-inc/design-system-mobile/assets/icons/components/Solid/Interface/Check";

export const usePinVerification = ({ openModal }: { openModal: OpenModal }) => {
  const openCreatePinModal = useCallback(() => {
    return new Promise<void>((resolve) => {
      const closePinModal = openModal("pin-verification", {
        pageInfo: {
          key: "create",
          title: "Create a 4-digit transaction PIN",
          headerTitle: "Create PIN",
        },
        confirmPageInfo: {
          key: "confirm",
          title: "Confirm transaction PIN",
          headerTitle: "Confirm PIN",
        },
        onPinSubmit: async (pin, confirm_pin) => {
          // TODO: Create PIN
          console.log(pin, confirm_pin);
          return new Promise<void>((done) => {
            setTimeout(() => {
              closePinModal();
              const closeSuccessModal = openModal("message", {
                title: "Success!",
                icon: (
                  <Check
                    width={40}
                    height={40}
                    color={tw`text-brand-green-accent`.color as string}
                  />
                ),
                renderContent: () => {
                  return (
                    <View style={tw`items-center my-12`}>
                      <Text style={tw`text-center text-white mb-6`}>
                        Transaction PIN set successfully.
                      </Text>
                      <Text style={tw`text-center text-white mb-6`}>
                        Do not share your PIN with anyone.
                      </Text>
                    </View>
                  );
                },
                buttonProps: {
                  title: "Done",
                  onPress: () => {
                    done();
                    closeSuccessModal();
                    resolve();
                  },
                },
              });
            }, 3000);
          });
        },
      });
    });
  }, [openModal]);
  const openVerifyPinModal = useCallback(() => {
    return new Promise<void>((resolve) => {
      const closePinModal = openModal("pin-verification", {
        pageInfo: {
          key: "verify",
          title: "Enter your 4-digit transaction PIN",
          headerTitle: "Enter PIN",
        },
        onPinSubmit: (pin) => {
          // TODO: Verify PIN
          console.log(pin);
          return new Promise<void>((done) => {
            setTimeout(() => {
              done();
              closePinModal();
              resolve();
            }, 3000);
          });
        },
      });
    });
  }, [openModal]);
  return {
    openCreatePinModal,
    openVerifyPinModal,
  };
};
