import { ApiResponse } from "../../types/app";
import { axiosInstance } from "../config";

export type Drawdown = {
  id: number;
  status: "active" | "pending" | "cancelled" | "overdue" | "complete";
  amount_drawn: number;
  amount_repaid: number;
  amount_owed: number;
  currency_code: string;
  repayment_amount: number;
  repayment_period: number;
  repayment_period_unit: string;
  repayment_profile: string;
  interest_rate: number;
  interest_rate_unit: string;
  payment_frequency: number;
  payment_frequency_unit: string;
  arrangement_fee: number;
  arrangement_fee_unit: string;
  reference: string;
  due_at: string;
  completed_at: null | string;
  realm_customer_id: null | string;
  realm_customer_data: null | string | any;
  realm_receipt_id: null | string;
  realm_receipt_data: null | string | any;
  bnpl_approval_id: number;
  user_id: number;
  created_at: string;
  updated_at: string;
  interest_amount: number;
  mifos_loan_id: number;
  payment_frequency_amount: number;
  is_deleted: boolean;
  bnpl_bundle_id: number;
  takes_charge: string;
  photo_ids: string;
  has_technical_issue: boolean;
  has_technical_issue_at: null | string;
  metadata: {};
  late_fees: number;
  bnpl_slot_id: number;
  slot_used_at: string;
  original_due_at: string;
  down_payment_percent: number;
  down_payment_amount: number;
  down_payment_amount_paid: number;
  down_payment_paid_at: null | string;
  down_payment_paid_by: null | string;
  is_szi: boolean;
  prepayment_rebate: number;
  is_accepted_by_customer: null | boolean;
  starts_at: string;
  arrangement_fee_percent: number;
  fees_withheld: number;
  security_deposit: number;
  total_deposit: number;
  rebate_reduction_percent: number;
  rebate_reduction_frequency: string;
  amount_rebated: number;
  old_mifos_loan_id: null | string;
  tags: Array<string>;
  is_replenished: boolean;
  supplier_id: number;
  dueRepayment: {
    status: string;
    due_at: string;
    totalAmount: number;
  };
  user: {
    id: number;
    firstname: string;
    lastname: string;
    mobile: string;
  };
  disbursements?: Array<{
    amount: number;
    currency_code: string;
    id: number;
    provider_label: string;
    status: "failed" | "pending" | "success" | "queued" | "processing";
  }>;
  repayments: Array<{
    amount_owed: number;
    amount_repaid: number;
    batch_no: number;
    bnpl_drawdown_id: number;
    completed_at: null | string;
    created_at: string;
    currency_code: string;
    down_payment_amount: number;
    down_payment_percent: number;
    due_at: string;
    has_technical_issue: boolean;
    has_technical_issue_at: null;
    id: number;
    interest_amount: number;
    is_deleted: boolean;
    late_fees: number;
    metadata: {};
    original_due_at: null;
    principal_amount: number;
    reference: string;
    repayment_amount: number;
    repayment_commission_calc_id: null;
    repayment_penalty_calc_id: null;
    starts_at: string;
    status: string;
    total_amount: number;
    updated_at: string;
    user_id: number;
  }>;
};

export const fetchDrawdowns = async (status?: string) => {
  let url = `/bcl/drawdown`;
  if (status) {
    url += `?status=${status}`;
  }
  return axiosInstance.get<null, ApiResponse<{}>>(url);
};

export const acceptDrawdown = async (id) => {
  return axiosInstance.post<null, ApiResponse<{}>>(
    `/bcl/drawdown/${id}/approve`
  );
};

export const declineDrawdown = async (id) => {
  return axiosInstance.delete<null, ApiResponse<{}>>(`/bnpl/drawdown/${id}`);
};

export const fetchSingleDrawdown = async (id) => {
  return axiosInstance.get<null, ApiResponse<{}>>(`/bcl/drawdown/${id}`);
};
